<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ fabric.idx + 1}} </td>

    <td  style="padding: 0px;width: 60px;">
      <input type="number" class="form-control" v-model="fabric.id" @keyup.enter="loadFabric(fabric.id)" />
    </td>
    <td style="padding: 0px;">
      <button class="btn btn-outline-success" @click="loadFabric(fabric.id)" tabindex="-1" >Find</button>
    </td>
    <td style="padding: 0px;">
      {{fabric.quality.name}}
    </td>
    <td style="padding: 0px;">
      {{fabric.color.name}}
    </td>
    <td style="padding: 0px;text-align: right;padding-right: 20px;">
      {{fabric.fab_weight | weightFormat}}
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="fabric" v-model="fabric.needle_damages" @change="showmsg(6)" />
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="fabric" v-model="fabric.hole_damages" @change="showmsg(7)"/>
    </td>
    <td style="padding: 0px;text-align: right;width: 60px;">
      <input type="number" class="form-control text-right" v-if="fabric" v-model="fabric.other_damages" @change="showmsg(8)"/>
    </td>
    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(Math.abs(index))" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'KnittingDetailRow',
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"OtherDamages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}')
      },
      myindex: {
        type: Number,
        default: () => 0,
      }
    },
    beforeMount () {
      const self = this;

      self.fabric = this.myrow;
      self.index = this.myindex;
      self.fabric.idx = this.myindex;
    },
    data () {
      return {
        index: 0,
        fabric : JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"OtherDamages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}')
      }
    },
    filters:{
      weightFormat (val){
        try{
          if(_.isNumber(val)){
            return val.toFixed(3);
          }else {
            return val;
          }

        }catch (e) {
          console.log(e);
        }

      }
    },
    methods:{
      removeRow(){
        this.$emit('onRemoveRow', (this.$data.fabric.idx ) );
      },
      rowValueEqualizer(idx) {
        try {
          const self = this;

          let fabric = this.$data.fabric;

          switch (idx) {
            case 1: //Fab Code

              break;
            case 2: //Item

              fabric.weight = this.$data.mapItems.get(fabric.item.id).wgt.toFixed(3);
              fabric.gst_rate = this.$data.mapItems.get(fabric.item.id).itmgrp.gst;
              fabric.item.group.gstrate = this.$data.mapItems.get(fabric.item.id).itmgrp.gst;

              break;
            case 3: //Rate

              fabric.rate = parseFloat(fabric.rate);

              this.$data.fabric.taxable_amt = fabric.qty * fabric.rate;
              this.$data.fabric.tax_amt = (fabric.taxable_amt * (fabric.gst_rate / 100)).toFixed(2);
              this.$data.fabric.net_amt = (fabric.taxable_amt + parseFloat(fabric.tax_amt)).toFixed(2);
              this.$data.fabric.tax_amt = parseFloat(fabric.tax_amt).toFixed(2);
              this.$data.fabric.tax_amt = fabric.tax_amt.toFixed(2);
              this.$data.fabric.taxable_amt = (fabric.qty * fabric.rate).toFixed(2);
              break;
            case 4:  //Qty

              fabric.qty = parseFloat(fabric.qty);

              this.$data.fabric.weight = (this.$data.fabric.qty * this.$data.mapItems.get(this.$data.fabric.item.id).wgt).toFixed(3);

              this.$data.fabric.taxable_amt = fabric.qty * fabric.rate;
              this.$data.fabric.tax_amt = (fabric.taxable_amt * (fabric.gst_rate / 100)).toFixed(2);
              this.$data.fabric.net_amt = (fabric.taxable_amt + parseFloat(fabric.tax_amt));
              this.$data.fabric.tax_amt = parseFloat(fabric.tax_amt).toFixed(2);
              this.$data.fabric.tax_amt = fabric.tax_amt.toFixed(2);
              this.$data.fabric.taxable_amt = (fabric.qty * fabric.rate).toFixed(2);
              break;
            case 5: //Weight
              break;
            case 6: //Taxable Amount

              this.$data.fabric.taxable_amt = parseFloat(fabric.taxable_amt);
              this.$data.fabric.rate = (fabric.taxable_amt / fabric.qty).toFixed(2);
              this.$data.fabric.tax_amt = (fabric.taxable_amt * (fabric.gst_rate / 100)).toFixed(2);
              this.$data.fabric.net_amt = (fabric.taxable_amt + parseFloat(fabric.tax_amt)).toFixed(2);
              break;
          }
        }catch (e) {
          alert(e);
        }
      },
      loadFabric(id){
        if(id < 1) {
          return;
        }

        $('tr').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.fabric = JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":1,"doc_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"qua_id":1,"col_id":1,"fab_weight":0,"fab_waste_weight":0,"yarn_waste_weight":0,"defects_count":0,"trn_id":1,"needle_damages":0,"hole_damages":0,"OtherDamages":0,"stentering_damages":0,"remarks":"","mtrs":0,"weight":0,"avg_weight":0,"shift":{"id":1,"status":"Active","name":""},"machine":{"id":1,"type":0,"name":"","status":"Active"},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1,"extrusion":false,"stentering":false,"warping":false,"designation":{"id":1,"status":"Active","name":"","min_wage":0}},"item":{"id":1,"status":"","type":"","name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]}}');
        self.$data.fabric.idx = self.myindex ;


        fetch(`${process.env.VUE_APP_ROOT_API}api/knitting/receipt/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            if(!_.isNull(resp.data)){
              self.$data.fabric = resp.data;
              self.$data.fabric.idx = self.myindex;
              self.showmsg(1);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('tr').unblock();
        });
      },
      showmsg(colIndex){

        switch (colIndex) {
          case 1:
            this.$emit('onrow_change', this.index, colIndex, this.fabric);
            break;
          case 6:
            this.$emit('onrow_change', this.index, colIndex, this.fabric.needle_damages);
            break;
          case 7:
            this.$emit('onrow_change', this.index, colIndex, this.fabric.hole_damages);
            break;
          case 8:
            this.$emit('onrow_change', this.index, colIndex, this.fabric.other_damages);
            break;
        }

        //alert(this.fabric.needle_damages);
      },
    }
  }
</script>

<style scoped>
input:focus { background: #feff00; }
textarea:focus { background: #feff00; }
select:focus { background: #feff00; }
button:focus { background: #feff00; }
</style>
